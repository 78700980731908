
/* Guidelines Content Styles */
.guidelines-content {
    margin: 10px; 
    background-color: aliceblue; 
    border-radius: 8px; 
    padding: 20px; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
    overflow-y: auto; 
    width: 100%;
    max-height: calc(100vh - 120px); 
    height: fit-content;
}

/* Headings Styles */
.guidelines-content h1 {
    font-size: 28px; 
    margin-bottom: 20px; 
}

.guidelines-content h2 {
    font-size: 24px; 
    margin-top: 20px; 
}

/* Paragraph Styles */
.guidelines-content p {
    font-size: 16px; 
    line-height: 1.6; 
}

/* Unordered List Styles */
.guidelines-content ul {
    list-style-type: disc; 
    margin-left: 20px; 
}

.guidelines-content li {
    margin-bottom: 10px; 
}

@media only screen and (max-width: 375px) {
    .guidelines-content h1 {
        font-size: 24px; /* Smaller font size */
    }

    .guidelines-content h2 {
        font-size: 20px; /* Smaller font size */
    }

    .guidelines-content p {
        font-size: 14px; /* Smaller font size */
    }
}