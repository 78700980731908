.main-footer {
    height: 60px;
    background-color: #1b1b67;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-top: auto
}

.main-header {
    height: 60px;
    max-width: 100%;
    width: 100%;
    background-color: #1b1b67;
    color: white;
    display: flex;
    justify-content: center;
    align-items:center;
    font-weight: bold
}