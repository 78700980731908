/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

* {

    font-family:  sans-serif;
    font-weight: 400;
    font-style: normal;
  
  
}
.app-background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url('/src/assets/images/rm373batch5-20.jpg');
}

/* CSS for react-chatbot-kit*/
.react-chatbot-kit-chat-container {
  position: relative;
  margin: 10px 10px;
  /* width: 275px; */
  width: 70vw;
  height: 100%;
  max-width: 90%;
    /* width: 100%; */
}

.react-chatbot-kit-chat-inner-container {
  height: 500px;
  background-color: #fff;
  border-radius: 3px;
  border-radius: 5px;
  width: 100%;
}

.react-chatbot-kit-chat-header {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: #1B3967;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #514f4f;
  padding: 12.5px;
  font-weight: bold;
  
}

.react-chatbot-kit-chat-input-container {
  position: relative;
  bottom: 0;
  display: flex;
}

.react-chatbot-kit-chat-message-container {
  padding: 0px 17.5px 8px 17.5px;
  overflow: scroll;
  height: 424px;
  border: 2px solid #d8d8d8;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;
}

.react-chatbot-kit-chat-input {
 
  padding: 12.5px;
  border: 2px solid #d8d8d8;
  font-size: 0.85rem;
  border-top: 1px solid #d8d8d8;
  border-bottom-left-radius: 5px;
}

.react-chatbot-kit-chat-input-form {
  width: 100%;
  display: flex;
}

.react-chatbot-kit-chat-input::placeholder {
  color: #585858;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #2898ec;
  width: 100px;
  border: none;
  color: #fff;
  border-bottom-right-radius: 5px;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: #fff;
  width: 15px;
  margin: 0 auto;
  padding: 10px;
}

.react-chatbot-kit-chat-bot-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-start;
  
}

.react-chatbot-kit-chat-bot-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.2rem;
  margin-right: 12.5px;
  background-color: #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-chat-bot-avatar-icon {
  fill: #494646;
  width: 10px;
  height: 10px;
}

.react-chatbot-kit-chat-bot-avatar-letter {
  color: green;
  margin: 0;
  padding: 0;
}

.react-chatbot-kit-chat-bot-avatar-letter > first-child {
  background-color: #1d1d1d;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #2898ec;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #fff;
  font-weight: medium;
  position: relative;
  width: 184.5px;
  margin-right: auto;
  text-align: left;
  flex-basis: content;
}

.react-chatbot-kit-chat-bot-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #2898ec;
  position: absolute;
  left: -7px;
  top: 13px;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
  height: 17px;
  width: 25px;
}

.chatbot-loader-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

#chatbot-loader #chatbot-loader-dot1 {
  animation: load 1s infinite;
}

#chatbot-loader #chatbot-loader-dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}

#chatbot-loader #chatbot-loader-dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}

.react-chatbot-kit-error {
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
}

.react-chatbot-kit-error-container {
  width: 260px;
}

.react-chatbot-kit-error-header {
  font-size: 1.3rem;
  color: #1d1d1d;
  margin-bottom: 30px;
}

.react-chatbot-kit-error-docs {
  display: block;
  margin: 25px auto;
  color: rgb(56, 104, 139);
  padding: 8px;
  border: 1px solid rgb(40, 152, 236);
  width: 130px;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
}

.react-chatbot-kit-user-chat-message-container {
  display: flex;
  margin: 15px 0;
  justify-content: flex-end;
}

.react-chatbot-kit-user-avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 12.5px;
  background-color: #3d4e8d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-chatbot-kit-user-avatar-icon {
  fill: #fff;
  width: 15px;
  height: 15px;
}

.react-chatbot-kit-user-chat-message {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #585858;
  font-weight: medium;
  position: relative;
  text-align: left;
}

.react-chatbot-kit-user-chat-message-arrow {
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #f1f1f1;
  position: absolute;
  right: -7px;
  top: 13px;
}

/* Chatbot CSS */
.chat-container .chat {
  padding: 25px 10px;
  display: flex;
  justify-content: center;
  color: white;
}

.chat-container .chat.outgoing {
  background-color: black;
  border: 1px solid ;

}

.chat-container .chat.incoming {
  background-color: black;
  border: 1px solid ;

}



.chat .chat-content {
  display: flex;
  max-width: 1200px;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

.chat .chat-details {
  display: flex;
  align-items: center;
}
.chat .chat-details p {
  white-space: pre-wrap;
  font-size: 1.05rem;
  padding: 0 50px 0 25px;
}
.typing-animation {
  display: inline-flex;
  padding-left: 25px;
}
.typing-animation .typing-dot {
  height: 7px;
  width: 7px;
  opacity: 0.5;
  margin: 0 3px;
  border-radius: 50%;
  background-color: white;
  animation: animationDots 1.5s ease-in-out infinite;
}

@keyframes animationDots {
  0%, 44% {
    transform: translateY(0px);
  }
  22% {
    opacity: 0.4;
    transform: translateY(-7px);
  }
  44% {
    opacity: 0.2;
  }
}

/* Typing conteiner */
.typing-container {
  width: 100%;
  bottom: 0;
  border: 1px solid;
  display: flex;
  justify-content: center;
}

.typing-container .typing-content {
  max-width: 950px;
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.typing-content .typing-textarea {
  width: 100%;
  display: flex;
  position: relative;

}

.typing-textarea textarea {
  width: 100%;
  height: 55px;
  border: none;
  resize: none;
  color: white;
  background-color: black;
  outline: 1px solid black;
  border-radius: 4px;
  padding: 15px 45px 15px 20px;
}

/* ==============Media Queries==================== */
@media screen and (max-width: 500px)
{
  .header-media {

    max-width: 100%;
    width: 100%; 
}
}

@media screen and (max-width: 539px)
{
  .react-chatbot-kit-chat-container {

    max-width: 100%;
    width: 100%; 
}
}

/* LOGIN Classes */
.login-flex {
  display: flex;
}

/* CHATBOT Classes */
.header-color {
  color: white;
}

.sideMenuVeritical {
  height: 100%;
}