.main-profile {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
}
.inside-profile {
    display: flex;
    flex-direction: row;
    flex: 1;
}
.space-card {
    display: flex;
}
.card-profile {
    margin: 50px 0 50px 50px;
    text-align: center;
}